import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import {
  collection,
  getDoc,
  getDocs,
  doc,
  query, where
} from "firebase/firestore";
import { db, auth } from './firebase.js'
import { useNavigate } from "react-router-dom"



function EditProfile(props) {
  const [newfName, setNewfName] = useState("")
  const [newlName, setNewlName] = useState("")
  const profilesCollectionRef = collection(db,"profiles")
  const certificatesCollectionRef = collection(db,"certificates")
  let { id } = useParams();
  const docRef = doc(db, "certificates", id);
  const Nav = useNavigate();


  function checkIfAuthenticated() {

    const authenticated = localStorage.getItem("authenticated")
    console.log(`auth is ${authenticated}`);
    if (authenticated == "true") {
      console.log("authenticated");
    } else {
      console.log("not authenticated");
      Nav("/")
      }
  }

  async function counterSetups() {
    checkIfAuthenticated()
    const q = query(collection(db, "certificates"), where("id", "==", `#${id}`));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });

if (querySnapshot.empty === true) {

} else {
    console.log(querySnapshot.empty);
}


    // if (docSnap.exists()) {
    //   console.log("Document data:", docSnap.data());
    // } else {
    //   // doc.data() will be undefined in this case
    //   console.log("No such document!");
    // }
  }


    useEffect(() => { setTimeout(counterSetups(),3000); }
    ,[])
return (
      <div>
        <div className="card" style={{margin:"auto", width:"50%", marginBottom:"20px"}}>
          <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}} >Profile Card</h5>
          <div className="card-body" style={{textAlign: "left"}}>
            <div >
                <h2>Date:{props.date}</h2>

            </div>
            <div >
                <h2>First Name:{props.fname} </h2>
                   <input className="form-control" id="fname" placeholder="First Name" type="text" onChange={(event) => {setNewfName(event.target.value)}}/>

                <h2>Last Name:{props.lname}</h2>
                   <input className="form-control" id="lname" placeholder="Last Name" type="text" onChange={(event) => {setNewlName(event.target.value)}}/>
            </div>
            <div>
                <h2> certificateBundleID: {props.id}</h2>
            </div>
            <div >
            {/*  */}
              <button className="btn btn-primary" style={{textAlign: "center"}}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default EditProfile;
