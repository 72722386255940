import React from 'react';
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import { db, auth } from './firebase.js'
import { v4 } from "uuid";
import { storage } from "./firebase";
import { useState,  useEffect  } from "react";
import { useNavigate } from "react-router-dom"

//TODO: REMOVE THE ABILITY TO SET THE LEVEL. IT SHOULD ALWAYS BE 1 FROM THIS PAGE
function AddProfileX(props) {

  const profilesCollectionRef = collection(db,"profiles")
  const certificatesCollectionRef = collection(db,"certificates")
  const [certificateUpload, setNewCertificateUpload] = useState(null)
  const [iD, setNewID] = useState("000000")
  const [newfName, setNewfName] = useState("")
  const [newlName, setNewlName] = useState("")
  const [newLevel, setNewLevel] = useState("1")
  const Nav = useNavigate();

  useEffect(() => { checkIfAuthenticated() },[])


  function reloadPage() {
    window.location.reload(false)
  }

  function checkIfAuthenticated() {

    const authenticated = localStorage.getItem("authenticated")
    console.log(`auth is ${authenticated}`);
    if (authenticated == "true") {
      console.log("authenticated");
    } else {
      console.log("not authenticated");
      Nav("/")
      }
  }

async function handleClick(event) {
      event.preventDefault()
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = `${month}-${day}-${year}`;
      let count = 0;
      let id = 0;
      let idStr = "";
      let  certificateBundleID = v4();




          console.log(`count is ${count}`);
      addDoc(profilesCollectionRef,{dateAdded:currentDate, certificateBundleID:certificateBundleID,fname:newfName,lname:newlName, level:newLevel})
console.log("added to profile collection");
             // console.log(`idStr = ${idStr}`);
       const certificatesListRef = ref(storage, `cert-pdfs/${newfName}${newlName}-ID-${iD}.pdf`);
       if (certificateUpload == null ) {



       addDoc(certificatesCollectionRef, { certificateBundleID:certificateBundleID, id:iD, level:0, date:"",link:"", fname:newfName, lname:newlName})

       // setTimeout(function() { reloadPage(); }, 1500);
       } else {
        console.log("certificate uploaded");
         uploadBytes(certificatesListRef, certificateUpload).then((snapshot) => {
           getDownloadURL(snapshot.ref).then((url) => {

                    addDoc(certificatesCollectionRef, { certificateBundleID:certificateBundleID, id:iD, level:newLevel, date:currentDate,link:url, fname:newfName, lname:newlName})


             setTimeout(() => {reloadPage()}, 3000)
           })
         })
         }
      }


  return (
<div>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div className="card" style={{margin:"auto", width:"50%"}}>

    <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}}>Add Profile</h5>
    <div className="card-body" style={{textAlign: "center"}}>
      <div style={{paddingBottom: "5px"}} >
          <input className="form-control" id="fname" placeholder="First Name" type="text" onChange={(event) => {setNewfName(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="lname" placeholder="Last Name" type="text" onChange={(event) => {setNewlName(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="id" placeholder="#XXXXXXX" type="text" onChange={(event) => {setNewID(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="level" placeholder="Level #" type="text" onChange={(event) => {setNewLevel(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "10px"}}>
          <input className="form-control" id="fileUpload" type="file" onChange={(event) => {setNewCertificateUpload(event.target.files[0]);}}/>
      </div>
      <div style={{marginRight: "10px"}}>
      {/*  */}
        <button className="btn btn-primary" onClick={handleClick} >Submit</button>

      </div>
    </div>
  </div>

</div>
  );
}


export default AddProfileX;
