import { Document, Page, pdfjs } from 'react-pdf'
// import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import { useState } from "react";



function PDFPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1)
    const [url, setURL] = useState("")
  function onDocumentLoadSuccess({ numPages: nextNumPages }, pdf) {
     setNumPages(nextNumPages);
   }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  return (
    <div>
      <Document file={{
      url: props.url }} options={options} onLoadError={console.error}  >
    <Page  pageNumber={1} />
      </Document>
    </div>
  );
}

export default PDFPage;
