import './App.css';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import { Notifications } from 'react-push-notification';
import Header from './Header';
import PDFPage from './PDFPage'
import AddProfile from './AddProfile'
import AddProfileX from './AddProfileX'
import EditProfile from './EditProfile'
import EditCertificate from './EditCertificate'
import AllProfiles from './AllProfiles'
import AddCertificate from './AddCertificate'
import AllCertificates from './AllCertificates'
import CertSearch from "./CertSearch"
import AddCertificateWithBundleID from "./AddCertificateWithBundleID"
import CertificateDisplayPage from "./CertificateDisplayPage"
import AdminDashboard from "./AdminDashboard"
import Login from "./Login"
import Register from "./Register"
import Footer from "./Footer"


function App() {

  return (
    <div>

    <Header/>
     <Notifications />
     
      <Routes>
            <Route exact path="/" element={<CertSearch/>}/>

            <Route path="/login" element={<Login/>} />

            <Route path="/search" element={<CertSearch/>}  />

            <Route path="/search/:id" element={<CertificateDisplayPage/>} />

            <Route path="/:id" element={<CertificateDisplayPage/>}/>

            <Route path="/all-profiles"element={<AllProfiles/>}/>

            <Route path="/admin-dashboard" element={<AdminDashboard/>}/>

            <Route path="/add-profilex" element={<AddProfileX/>}/>

            <Route path="/add-certificate-to-profile" element={<AddCertificateWithBundleID/>}/>

            <Route path="/edit-profile/:id" element={<EditProfile/>}/>

            <Route path="/all-certificates" element={<AllCertificates/>} />

          //  <Route path="/register" element={<Register/>}/>

            <Route path="/edit-certificate/:id" element={<EditCertificate/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
