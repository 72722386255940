import React from "react"
import {
  collection,
  getDocs,
  doc,
} from "firebase/firestore";
import { db } from './firebase.js'
import { storage } from "./firebase";
import { useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf'
import ProfileCard from './ProfileCard'
import Header from './Header';


function AllProfiles() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const profilesCollectionRef = collection(db,"profiles")
  const certificatesCollectionRef = collection(db,"certificates")
  const [profiles, setProfiles] = useState([])

 function counterSetups() {

  getDocs(profilesCollectionRef).then((response) => {

    console.log(`response count is ${response.docs.length}`);
    response.docs.forEach((item) => {

                                    let newProfile = item.data()

                                    profiles.push(newProfile)
                                    setProfiles(profiles)
                                    })
})}


  useEffect(() => { setTimeout(counterSetups(),3000)}
  ,[])

  function createProfile(profile) {
    return (
      <ProfileCard key={profile.key}
      id={profile.certificateBundleID}
      level={profile.level}
      date={profile.dateAdded}
      fname={profile.fname}
      lname={profile.lname}
/>
    )
  }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

  return (
    <div>
      <h1>All Profiles</h1>
    {profiles.map(createProfile)}
    </div>
  );
}

export default AllProfiles;
