import React from 'react';
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import { db, auth } from './firebase.js'
import { v4 } from "uuid";
import { storage } from "./firebase";
import { useState,  useEffect  } from "react";
import { useNavigate } from "react-router-dom"
import addNotification from 'react-push-notification';


function AddCertificateWithBundleID(props) {

  const profilesCollectionRef = collection(db,"profiles")
  const certificatesCollectionRef = collection(db,"certificates")
  const [certificateUpload, setNewCertificateUpload] = useState(null)
  const [iD, setNewID] = useState("")
  const [newfName, setNewfName] = useState("")
  const [newlName, setNewlName] = useState("")
  const [newLevel, setNewLevel] = useState("1")
  const [certificateBundleID, setCertificateBundleID ] = useState("")
  const Nav = useNavigate();

  useEffect(() => { checkIfAuthenticated() },[])


  function reloadPage() {
    window.location.reload(false)
  }

  function loadAllCertificates() {
    Nav("/all-certificates")
  }

  function checkIfAuthenticated() {

    const authenticated = localStorage.getItem("authenticated")
    console.log(`auth is ${authenticated}`);
    if (authenticated == "true") {
      console.log("authenticated");
    } else {
      console.log("not authenticated");
      Nav("/")
      }
  }

  const missingPDFUploadNotification = () => {
    addNotification({
        title: 'Missing PDF upload',
        subtitle: 'Click "Choose File" below',
        message: 'You need to upload a pdf with this profile',
        theme: 'darkblue',
        native: false // when using native, your OS will handle theming.
    });
};

async function handleClick(event) {
      event.preventDefault()
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = `${month}-${day}-${year}`;
      let count = 0;
      let id = 0;
      let idStr = "";


       const certificatesListRef = ref(storage, `cert-pdfs/${newfName}${newlName}-level-${newLevel}.pdf`);
          if (certificateUpload == null ) {

            missingPDFUploadNotification()
           // setTimeout(function() { reloadPage(); }, 1500);

           } else {
                    console.log("certificate uploaded");
                     uploadBytes(certificatesListRef, certificateUpload).then((snapshot) => {
                       getDownloadURL(snapshot.ref).then((url) => {

                         addDoc(certificatesCollectionRef,
                            { certificateBundleID:certificateBundleID,
                               id:iD,
                                level:newLevel,
                                 date:currentDate,
                                 link:url,
                                  fname:newfName,
                                   lname:newlName
                              }  )
                              addNotification({
                                  title: 'Upload Successful!',
                                  subtitle: '',
                                  message: 'Loading all certificates page',
                                  theme: 'darkblue',
                                  native: false // when using native, your OS will handle theming.
                              });
                          setTimeout(() => {loadAllCertificates()}, 5000)
                       })
                     })
                    }

   }


  return (
<div>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div className="card" style={{margin:"auto", width:"50%"}}>

    <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}}>Add Profile</h5>
    <div className="card-body" style={{textAlign: "center"}}>
      <div style={{paddingBottom: "5px"}} >
          <input className="form-control" id="fname" placeholder="First Name" type="text" onChange={(event) => {setNewfName(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="lname" placeholder="Last Name" type="text" onChange={(event) => {setNewlName(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="id" placeholder="#XXXXXXX" type="text" onChange={(event) => {setNewID(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="level" placeholder="Certificate Bundle ID" type="text" onChange={(event) => {setCertificateBundleID(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "20px"}}>
          <input className="form-control" id="level" placeholder="Level #" type="text" onChange={(event) => {setNewLevel(event.target.value)}}/>
      </div>
      <div style={{paddingBottom: "10px"}}>
          <input className="form-control" id="fileUpload" type="file" onChange={(event) => {setNewCertificateUpload(event.target.files[0]);}}/>
      </div>
      <div style={{marginRight: "10px"}}>
      {/*  */}
        <button className="btn btn-primary" onClick={handleClick} >Submit</button>

      </div>
    </div>
  </div>

</div>
  );
}


export default AddCertificateWithBundleID;
