import React from 'react';
import ReactDOM from 'react-dom';
import PDFPage from './PDFPage'
import { Link } from 'react-router-dom'


const CertificateCard = (props) =>  {
  function handleClick() {

  }
return (
      <div>
        <div className="card" style={{margin:"auto", width:"50%", marginBottom:"20px"}}>
          <h4 className="card-title" style={{paddingTop:"20px", paddingLeft:"10px"}} >Certificate Card</h4>
          <div className="card-body" style={{textAlign: "left"}}>

            <div>
                <p>Name: {props.fname} {props.lname}</p>
            </div>
            <div>
                <p> Certificate ID: {props.id}</p>
            </div>
            <div>
                <p>Date Added: {props.date}</p>
            </div>
            <div>
              <Link to={'/edit-certificate/' + props.id} >Edit</Link>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CertificateCard;
