import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import {
  collection,
  getDoc,
  getDocs,
  doc,
  updateDoc ,
  query,
  where,
  deleteDoc
} from "firebase/firestore";
import { db, auth } from './firebase.js'
import PDFPage from './PDFPage'
import { set } from "firebase/database";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import { storage } from "./firebase";

import { useNavigate } from "react-router-dom"


function EditCertificate(props) {
  const [newfName, setNewfName] = useState("")
  const [newlName, setNewlName] = useState("")
  const [newLink, setNewLink] = useState("")
  const [newDate, setNewDate] = useState("")
  const [newLevel, setNewLevel] = useState("1")
  const [certificateUpload, setNewCertificateUpload] = useState(null)

  const [newCertificateBundleID, setNewCertificateBundleID] = useState("")
  const [certificateID, setNewCertificateID] = useState("")
  const profilesCollectionRef = collection(db,"profiles")
  const certificatesCollectionRef = collection(db,"certificates")
  let { id } = useParams();
  const docRef = doc(db, "certificates", id);
  const Nav = useNavigate();


      function checkIfAuthenticated() {

        const authenticated = localStorage.getItem("authenticated")
        console.log(`auth is ${authenticated}`);
        if (authenticated == "true") {
          console.log("authenticated");
        } else {
          console.log("not authenticated");
          Nav("/")
          }
      }

  function saveNewfName() {
    const certRef = doc(db, "certificates", certificateID);

    ;
    const update = document.getElementById('fname').value
    console.log(`update is ${update}`)
    const updateData = {

      fname: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),4000)
  }

  async function deleteCertificate() {
console.log(certificateID,0);
    await deleteDoc(doc(db,"certificates", certificateID))
    setTimeout(Nav("/all-certificates"), 5000)
  }

  function saveNewlName() {
    const certRef = doc(db, "certificates", certificateID);

    ;
    const update = document.getElementById('lname').value
    console.log(`update is ${update}`)
    const updateData = {

      lname: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),4000)
  }

  function saveNewLevel() {
    const certRef = doc(db, "certificates", certificateID);

    const update = document.getElementById('level').value
    console.log(`update is ${update}`)
    const updateData = {

      level: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),4000)
  }


  function saveNewDate() {
    const certRef = doc(db, "certificates", certificateID);

    ;
    const update = document.getElementById('date').value
    console.log(`update is ${update}`)
    const updateData = {

      date: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),4000)
  }

  function saveNewLink() {
    const certRef = doc(db, "certificates", certificateID);


    const update = document.getElementById('url').value
    console.log(`update is ${update}`)
    const updateData = {

      link: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),3000)
  }

  function saveNewPDF() {
    const certRef = doc(db, "certificates", certificateID);
    const certificatesListRef = ref(storage, `cert-pdfs/${newfName}${newlName}.pdf`);

    uploadBytes(certificatesListRef, certificateUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const update = url
        console.log(`update is ${update}`)
        const updateData = {

          link: update
        }

        updateDoc(certRef, updateData)
        setTimeout(() => {pageRefresh()}, 3000)
      })
    })
  }
  function saveNewCertificateBundleID() {
    const certRef = doc(db, "certificates", certificateID);

    ;
    const update = document.getElementById('BunldeID').value
    console.log(`update is ${update}`)
    const updateData = {

      certificateBundleID: update
    }

    updateDoc(certRef, updateData)
    setTimeout(pageRefresh(),3000)
  }


  function pageRefresh() {
    window.location.reload(false)

  }

  async function counterSetups() {
    checkIfAuthenticated()
    const docSnap = await getDoc(docRef);
    const q = query(collection(db, "certificates"), where("id", "==", `${id}`));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
     console.log(doc.data());
     let result = doc.data()
     setNewLevel(result.level)
     setNewDate(result.date)
     setNewLink(result.link)
     setNewlName(result.lname)
     setNewfName(result.fname)
     setNewCertificateBundleID(result.certificateBundleID)
     setNewCertificateID(doc.id)

    });

if (querySnapshot.empty === true) {

} else {
//    console.log(querySnapshot.empty);
}


    // if (docSnap.exists()) {
    //   console.log("Document data:", docSnap.data());
    // } else {
    //   // doc.data() will be undefined in this case
    //   console.log("No such document!");
    // }
  }


    useEffect(() => { setTimeout(counterSetups(),3000); }
    ,[])
return (
      <div>
        <div className="card" style={{margin:"auto", width:"90%", marginBottom:"20px"}}>
          <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}} >Certificate Details</h5>
          <div className="card-body" style={{textAlign: "left"}}>
            <div >
                <p><b>Date:</b> {newDate}</p>
                <input className="form-control" id="date" placeholder="Date" type="text" />
                <button className="btn btn-primary" style={{textAlign: "center"}} style={{margin:"10px"}} >Save Date</button>
            </div>
            <br/>
            <div >
                <p><b>First Name:</b> {newfName} </p>
                   <input className="form-control" id="fname" placeholder="First Name" type="text" />
                   <button className="btn btn-primary" style={{textAlign: "center"}} onClick={saveNewfName} style={{margin:"10px"}}>Save First Name</button>
<br/>
                <p><b>Last Name:</b> {newlName}</p>
                   <input className="form-control" id="lname" placeholder="Last Name" type="text" />
                   <button className="btn btn-primary" style={{textAlign: "center"}} onClick={saveNewlName} style={{margin:"10px"}}>Save Last Name</button>
            </div>
            <br/>
            <div >
                <p><b>Level:</b> {newLevel}</p>
                <input className="form-control" id="level" placeholder="X" type="text" />
                <button className="btn btn-primary" style={{textAlign: "center"}} onClick={saveNewLevel} style={{margin:"10px"}}>Save Level</button>
            </div>
            <br/>
            <div>
                <p><b> CertificateBundleID:</b> {newCertificateBundleID}</p>
                <input className="form-control" id="bundleID" placeholder="BunldeID" type="text" />
                <button className="btn btn-primary" style={{textAlign: "center"}} onClick={saveNewCertificateBundleID} style={{margin:"10px"}}>Save BunldeID</button>
            </div>
            <div>
                <p> <b>URL:</b> {newLink}</p>
                <input className="form-control" id="url" placeholder="URL" type="text"  />
                <button className="btn btn-primary" style={{textAlign: "center"}} onClick={saveNewLink} style={{margin:"10px"}}>Save Link</button>

            </div>
            <br/>
            <div >
            {/*  */}
            </div>
            <div>
            <PDFPage url={newLink}/>
            <input className="form-control" id="fileUpload" type="file" style={{ margin:"10px"}} onChange={(event) => {setNewCertificateUpload(event.target.files[0]);}}/>
            <button className="btn btn-primary" style={{textAlign: "center", marginTop:"10px"}} onClick={saveNewPDF} style={{paddingBottom: "10px", paddingTop: "10px"}}>Save PDF</button>
            </div>
            <div style={{ marginTop:"10px"}} >
            <button className="btn btn-danger" style={{textAlign: "center"}} onClick={deleteCertificate} style={{paddingBottom: "10px", paddingTop: "10px"}}>Delete Certificate </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default EditCertificate;
