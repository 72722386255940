import React from 'react';
import ReactDOM from 'react-dom';
import { Link , useNavigate  } from "react-router-dom";
import { useState, useEffect } from "react";



const ProfileCard = (props) =>  {
const Nav = useNavigate();

//Write a function within useEffect that will create buttons based on
// how many certificates have been found linked to the certificateBundleID

return (
      <div>
        <div className="card" style={{margin:"auto", width:"50%", marginBottom:"20px"}}>
          <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}} >Profile Card</h5>
          <div className="card-body" style={{textAlign: "left"}}>

            <div style={{display:"inline"}}>
                <h2></h2> <h3>Name: {props.fname} {props.lname}</h3>
            </div>
            <div style={{display:"inline"}}>
                <h3> Certificate Bundle ID - </h3> <p> {props.id} </p>
            </div>
            <div >
            {/*  */}
              <Link to={'/edit-profile/' + props.id}>
                <button className="btn btn-primary" style={{textAlign: "center", margin:"10px"}}>Edit Profile</button>
              </Link>
              <Link to={'/add-certificate-to-profile'}>
                <button className="btn btn-primary" style={{textAlign: "center"}}>Add Certificate </button>
              </Link>
            </div>
            <div >

            </div>
          </div>
        </div>
      </div>
    )
}

export default ProfileCard;
