
import PDFPage from './PDFPage'

import { Document, Page, pdfjs } from 'react-pdf'
// import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useNavigate, useParams } from "react-router-dom"
import { useState , useEffect} from "react";
import { db } from './firebase.js'
import {
  collection,
  getDoc,
  getDocs,
  doc,
  query, where
} from "firebase/firestore";


function CertificateDisplayPage() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
 const navigate = useNavigate()
    const [textField, setTextField] = useState("");
    const [newfName, setNewfName] = useState("")
    const [newlName, setNewlName] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [url, setURL] = useState("")
    let { id } = useParams();

  useEffect(() => { loader() },[])

    const certificatesCollectionRef = collection(db,"certificates")

   async function loader() {

       const q = query(collection(db, "certificates"), where("id", "==", `${id}`));
       const querySnapshot = await getDocs(q);
       console.log(querySnapshot.empty);

       if (querySnapshot.empty == true) {

       } else {
         querySnapshot.forEach((doc) => {
           // doc.data() is never undefined for query doc snapshots

           let returned = doc.data()
           if ( returned.level == 0 ) {
             console.log("level is 0");
           } else {
                setNewfName(returned.fname)
                setNewlName(returned.lname)
                setURL(returned.link)

           }
           console.log(returned,1);
         });
       }
   }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  return (
    <div>
    <br/>
    <br/>
    <div>
      <div className="card" style={{margin:"auto", width:"800px", marginBottom:"20px"}}>
        <h5 className="card-title" style={{paddingTop:"20px", paddingLeft:"20px"}} ></h5>
        <div className="card-body" style={{textAlign: "left"}}>

          <div><PDFPage style={{width:"100%", height:"100%"}} url={url}/> </div>

        </div>
      </div>
    </div>
    </div>
  );
}

export default CertificateDisplayPage;
