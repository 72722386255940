import React from "react"
import {
  collection,
  getDocs,
  doc,
  query,
  orderBy
} from "firebase/firestore";
import { db, auth } from './firebase.js'
import { storage } from "./firebase";
import { useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf'
import CertificateCard from './CertificateCard'
import Header from './Header';
import { useNavigate } from "react-router-dom";

function AllCertificates() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  let hasRan = false
  const certificatesCollectionRef = collection(db,"certificates")
  const [certificates, setCertificates] = useState([])
  const Nav = useNavigate()


    function checkIfAuthenticated() {

      const authenticated = localStorage.getItem("authenticated")
      console.log(`auth is ${authenticated}`);
      if (authenticated == "true") {
        console.log("authenticated");
      } else {
        console.log("not authenticated");
        Nav("/")
        }
    }
function start() {
checkIfAuthenticated()
  console.log("starting");
  console.log(`${certificates.length}`);
  if (certificates.length == 0) {
    hasRan = true
    console.log("running");
    setTimeout(counterSetups(), 3000)
  } else {

console.log("running 2");
  }
}
async function counterSetups() {
  var holder = []
  const q = query(certificatesCollectionRef, orderBy("id"))

  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots

  const item = doc.data()
  let newCertificate = item
  newCertificate.key = item.id
  // console.log(newCertificate);

    holder.push(newCertificate)
      certificates.push(newCertificate)
      setCertificates(holder)

})

}

  useEffect(() => { start() },[certificates])


  function createProfile(profile) {
    return (
      <CertificateCard key={profile.key}
      id={profile.id}
      date={profile.date}
      fname={profile.fname}
      lname={profile.lname}
      url={profile.link}
/>
    )
  }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

  return (
    <div>
      <h1 style={{paddingLeft:"30px"}}>All Certificates</h1>
      {certificates.map(createProfile)}
    </div>
  );
}

export default AllCertificates;
